import React from 'react';

import styles from './ChatWindow.module.scss';
import IconButton from '../../../components/IconButton';
import { FiChevronLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useChat } from '../../../store/slicers/chat';
import Title from '../../../components/Title';
import ChatMessagesContainer from '../ChatMessagesContainer';
import ChatWindowHeader from '../ChatWindowHeader';
import ChatTabsContainer from '../ChatTabsContainer';
import Condition from '../../../components/Condition';
import ChatFilesContainer from '../ChatFilesContainer';

const ChatWindow = ({
  onBackClick,
}) => {
  const [t, i18n] = useTranslation();
  const chat = useChat();

  if (!chat.activeChatId) return (
    <div className={styles.windowChat}>
      <div className={styles.windowChatContainer}>
        <div className={styles.windowChatPlaceholder}>
          <img src="/online-app/images/chat-placeholder-empty.png" alt=""/>
          <Title level={4}>{t('Выберите собеседника из окна слева')}</Title>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.windowChat}>
      <div className={styles.windowChatContainer}>
        <div className={styles.windowChatHeader}>
          {
            onBackClick && (
              <div className={styles.windowChatHeaderBack}>
                <IconButton
                  type="button"
                  color="transparent"
                  size="large"
                  icon={<FiChevronLeft />}
                  onClick={onBackClick}
                />
              </div>
            )
          }
          <div className={styles.windowChatHeaderUser}>
            <ChatWindowHeader />
          </div>
        </div>
        <div className={styles.windowChatTabs}>
          <ChatTabsContainer />
        </div>
        <div className={styles.windowChatMessages}>
          <Condition when={chat.activeTabId} is={1}>
            <ChatMessagesContainer />
          </Condition>
          <Condition when={chat.activeTabId} is={2}>
            <ChatFilesContainer />
          </Condition>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
