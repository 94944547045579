import { joinExisting } from './utils';

export const baseUrl = process.env.REACT_APP_HOST;

export default {
  login: () => joinExisting(['account/student/login']),
  refreshToken: () => joinExisting(['/account/public/tokens/refresh']),

  signupPhoneConfirmation: () => joinExisting(['account/student/verify/request']),
  verifyPhone: () => joinExisting(['account/student/verify']),
  newPhoneVerification: () => joinExisting(['account/student/change-phone/request']),
  verifyNewPhone: () => joinExisting(['account/student/change-phone']),
  setPassword: () => joinExisting(['account/student/signup']),
  changePassword: () => joinExisting(['account/student/change-password']),
  myProfile: () => joinExisting(['student-profile/student/me/profile']),
  requestRecoveryCode: () => joinExisting(['account/student/password-recovery/request']),
  recoveryPassword: () => joinExisting(['account/student/password-recovery']),
  generateTelegramBotLink: () => joinExisting(['student-bot/student/registration-urls']),
  linkedTelegramBotAccounts: () => joinExisting(['student-bot/student/subscribed-bot-users']),
  unsubscribeTelegramBotAccount: (id) => joinExisting(['student-bot/student/subscribed-bot-users', id]),

  // notifications
  notificationList: () => joinExisting(['system-notification/notifications']),
  readAllNotifications: () => joinExisting(['system-notification/notifications/mark-as-read']),

  //tutors
  tutors: (id) => joinExisting(['search/public/tutors', id]),
  tutorsLite: (id) => joinExisting(['search/public/tutors/lite', id]),
  tutorSingleSchedules: () => joinExisting(['learning/public/lesson-schedules/available/single']),
  tutorWeeklySchedules: () => joinExisting(['learning/public/lesson-schedules/available/weekly']),
  tutorReviews: () => joinExisting(['search/public/tutor-reviews']),
  createTutorReview: () => joinExisting(['search/student/tutor-reviews']),

  //dictionaries
  subjects: (id) => joinExisting(['search/public/dicts/subjects', id]),
  faqs: () => joinExisting(['search/public/faq']),
  priceCategories: (id) => joinExisting(['search/public/dicts/price-categories', id]),
  ageCategories: (id) => joinExisting(['search/public/dicts/age-categories', id]),
  learningGoals: (id) => joinExisting(['search/public/dicts/learning-goals', id]),
  qualifications: (id) => joinExisting(['search/public/dicts/qualifications', id]),
  workExperienceCategories: (id) => joinExisting(['search/public/dicts/work-experience-categories', id]),
  languages: (id) => joinExisting(['search/public/dicts/languages', id]),
  languageSkillLevels: (id) => joinExisting(['search/public/dicts/language-skill-levels', id]),
  educationLevels: (id) => joinExisting(['search/public/dicts/education-levels', id]),
  educationInstitutions: (id) => joinExisting(['search/public/dicts/education-institutions', id]),
  packageCancellationReasons: () => joinExisting(['learning/public/package-cancellation-request-reasons']),

  // files
  uploadFile: () => joinExisting(['/static-content/student/files']),

  // schedule
  scheduledLessons: () => joinExisting(['learning/student/lesson-schedules/lessons']),
  lessonSchedules: () => joinExisting(['learning/student/lesson-schedules']),
  cancelLesson: (scheduleId) => joinExisting(['learning/student/lesson-schedules', scheduleId, 'cancel-lesson']),
  rescheduleLesson: (scheduleId) => joinExisting(['learning/student/lesson-schedules', scheduleId, 'request-transfer-lesson']),
  rescheduledLessonList: () => joinExisting(['learning/student/scheduled-lesson-transfer-requests']),
  cancelledLessonList: () => joinExisting(['learning/student/scheduled-lesson-cancellations']),
  approveReschedule: (rescheduleId) => joinExisting(['learning/student/scheduled-lesson-transfer-requests', rescheduleId, 'approve']),
  rejectReschedule: (rescheduleId) => joinExisting(['learning/student/scheduled-lesson-transfer-requests', rescheduleId, 'reject']),
  checkSchedule: () => joinExisting(['learning/student/lesson-schedules/check']),

  // trial lessons
  bookTrialLesson: () => joinExisting(['student-promotion/student/trial-lessons']),
  availableTrialLesson: () => joinExisting(['student-promotion/student/trial-lessons/available']),

  // buy packages
  tariffs: () => joinExisting(['learning/public/tariffs']),
  buyPackage: () => joinExisting(['learning/student/packages/buy']),
  bookWithoutBuy: () => joinExisting(['learning/student/lesson-schedules']),
  freeBalance: () => joinExisting(['learning/student/balance']),

  // list packages
  packages: () => joinExisting(['learning/student/packages']),
  packageCancellationRequests: () => joinExisting(['learning/student/package-cancellation-requests']),
  transferPackage: (id) => joinExisting(['learning/student/packages', id, 'change-tutor']),
  unpaidInvoices: (id) => joinExisting(['learning/student/invoices', id]),
  packagesHistory: () => joinExisting(['learning/student/packages-history']),

  // chats
  dialogs: (id) => joinExisting(['chat/chats', id]),
  chatMessages: (id) => joinExisting(['chat/text-messages', id]),
  chatFiles: (id) => joinExisting(['chat/file-messages', id]),
  readMessage: (id) => joinExisting(['chat/messages/mark-as-read', id]),
  readAllFiles: (id) => joinExisting(['chat/chats', id, 'file-messages/mark-as-read']),
  readAllMessages: (id) => joinExisting(['chat/chats', id, 'text-messages/mark-as-read']),

  // lessons
  lessons: (id) => joinExisting(['learning/student/lessons', id]),

  // favorites tutors
  favoriteTutors: (id) => joinExisting(['search/student/favorite/tutors', id]),

  // room
  roomInfo: (id) => joinExisting(['room/student/general-rooms', id]),
  addIceCandidate: (id) => joinExisting(['room/student/general-rooms', id, 'ice']),
  setAnswer: (id) => joinExisting(['room/student/general-rooms', id, 'sdp']),
  sendWaitSignal: (id) => joinExisting(['room/student/general-rooms', id, 'wait']),

  // static contents
  socialLinks: () => joinExisting(['content/public/social-network-pages']),
  quoteOfTheDay: () => joinExisting(['content/public/todays-quote']),

  // service config
  serviceConfig: () => joinExisting(['learning/public/service-configs']),
  userScheduleLimit: () => joinExisting(['learning/public/limits/my/new-schedules']),
};
