import React from 'react';
import cn from 'classnames';

import styles from './PackagesHistory.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetPackagesHistoryQuery } from '../../../store/apis/tariffs';
import ContentLoader from 'react-content-loader';
import ContentEmpty from '../../../components/ContentEmpty';
import { head, keys } from 'ramda';
import BalanceCard from '../BalanceCard';

const PackagesHistory = () => {
  const [t] = useTranslation();

  const {
    data: packageHistory,
    isLoading: isPackageHistoryLoading,
    isFetching: isPackageHistoryFetching,
  } = useGetPackagesHistoryQuery({ state_not_id: 1, page: -1 });

  if (isPackageHistoryLoading || isPackageHistoryFetching) return (
    <ContentLoader
      backgroundColor={'#c8c8c8'}
      foregroundColor={'#eceaea'}
      width="100%"
      height="340"
      style={{ padding: '0 1em', boxSizing: 'border-box' }}
      speed={2}
    >
      <rect x="0" y="0" rx="6" ry="6" width="49%" height="340" />
      <rect x="51%" y="0" rx="6" ry="6" width="49%" height="340" />
    </ContentLoader>
  );

  if (packageHistory && packageHistory.results.length === 0) return (
    <ContentEmpty
      imgSrc="/online-app/images/empty-sleeping.png"
      title={t('У Вас нет истории пополнений')}
      subtitle={t('Здесь будет история купленных пакетов')}
    />
  );

  const uniqTutors = packageHistory
    ? packageHistory.results.reduce((acc, pack, i) => {
      const tutorId = pack.tutor.id;
      if (acc[tutorId]) {
        const currentTutorPacks = acc[tutorId].packs;
        const currentTutor = acc[tutorId];

        const newTutorInfo = {
          ...currentTutor,
          packs: [...currentTutorPacks, pack],
        }

        return {
          ...acc,
          [tutorId]: newTutorInfo,
        }
      }

      const newTutorInfo = {
        tutor: pack.tutor,
        packs: [pack],
      };

      return { ...acc, [tutorId]: newTutorInfo };
    }, {})
    : {};

  return (
    <div className={styles.balances}>
      {keys(uniqTutors).map((tutorId) => (
        <div className={styles.balancesItem} key={tutorId}>
          <BalanceCard
            tutor={uniqTutors[tutorId].tutor}
            packs={uniqTutors[tutorId].packs?.filter((pack) => pack.state.id !== 1)}
            allTutors={uniqTutors}
            canDoPackageActions={false}
            withoutCreditRow
          />
        </div>
      ))}
    </div>
  );
};

export default PackagesHistory;
