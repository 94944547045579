export const externalRoutes = {
  landingTutors: '/online/tutors',
  faq: '/online/faq',
  about: '/online/contacts',
  root: (lang) => {
    switch (lang) {
      case 'ru': return '/online/ru';
      case 'uz': return '/online';
      case 'en': return '/online/en';
      default: return '/online';
    }
  },
}
