import React, { useCallback } from 'react';

import styles from './SetPassword.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ref, string } from 'yup';
import { createDataSchema } from '../../../yup';
import appRoutes from '../../../app/appRoutes';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import PasswordInputController from '../../../components/PasswordInput/PassswordInputController';
import Button from '../../../components/Button';
import { useSetPasswordMutation } from '../../../store/apis/auth';
import localforage from 'localforage';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';

const schema = createDataSchema({
  password: string().required(),
  passwordConfirmation: string().oneOf([ref('password'), null]),
});
const SetPassword = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const query = useQueryString();

  const [setPassword, { isLoading, isFetching }] = useSetPasswordMutation();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      const credentials = await setPassword(values).unwrap();
      localStorage.setItem('credentials', JSON.stringify(credentials));
      navigate({
        pathname: appRoutes.registerFillProfile(),
        search: queryString.stringify(query),
      });
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [navigate, setPassword]);

  return (
    <div className={styles.register}>
      <form onSubmit={submit(handleSubmit)}>
        <div className={styles.registerHeader}>
          <Title level={3}>{t('Установить пароль')}</Title>
          <Text level={2} color="secondary">
            {t('Установите пароль от аккаунта и запомните его')}
          </Text>
        </div>
        <div className={styles.registerBody}>
          <PasswordInputController
            name="password"
            control={control}
            label={t('Пароль')}
            placeholder={t('Введите пароль')}
          />
          <PasswordInputController
            name="passwordConfirmation"
            control={control}
            label={t('Повторите пароль')}
            placeholder={t('Введите пароль еще раз')}
          />
          <Button type="submit" fullWidth loading={isLoading || isFetching} className="Button_button__JBBzO_Password">
            {t('Продолжить')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SetPassword;
