import React, { useCallback } from 'react';

import styles from './Login.module.scss';
import Title from '../../../components/Title';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text';
import { Link, useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import { useForm } from 'react-hook-form';
import PhoneInputController from '../../../components/PhoneInput';
import Button from '../../../components/Button';
import PasswordInputController from '../../../components/PasswordInput/PassswordInputController';
import { createDataSchema } from '../../../yup';
import { string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../../../store/apis/auth';
import localforage from 'localforage';
import { socket } from '../../../socket';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';

const loginFormSchema = createDataSchema({
  username: string().required().length(12),
  password: string().required(),
});

const Login = () => {
  const [t] = useTranslation();
  const query = useQueryString();
  const navigate = useNavigate();
  const [login, { isLoading: isLoginLoading, isFetching: isLoginFetching }] = useLoginMutation();
  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(loginFormSchema),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      const credentials = await login(values).unwrap();
      localStorage.setItem('credentials', JSON.stringify(credentials));
      navigate({
        pathname: appRoutes.proxy(),
        search: queryString.stringify(query),
      });
      socket.connect();
      toast.success(t('С возвращением'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [login, navigate, t]);

  return (
    <div className={styles.login}>
      <form className={styles.loginForm} onSubmit={submit(handleSubmit)}>
        <div className={styles.loginFormHeader}>
          <Title level={3}>{t('Вход')}</Title>
          <Text level={2} color="secondary">
            {t('Еще нет аккаунта?')}{' '}
            <Link
              to={{
                pathname: appRoutes.register(),
                search: queryString.stringify(query),
              }}
            >
              {t('Регистрация')}
            </Link>
          </Text>
        </div>
        <div className={styles.loginFormBody}>
          <PhoneInputController
            control={control}
            name="username"
            label={t('Номер телефона')}
            placeholder="+998 99 9999999"
            error="Формат мобильного телефона неправильный"
            inputFormat="+### ## #######"
            mask=" "
          />
          <PasswordInputController
            control={control}
            name="password"
            label={t('Пароль')}
            placeholder={t('Введите пароль')}
          />
          <Button className="Button_button__JBBzO_Enter" type="submit" fullWidth loading={isLoginLoading || isLoginFetching}>
            {t('Войти')}
          </Button>
          {
            process.env.REACT_APP_ENV === 'development' && (
              <div className={styles.appContentAsideItem}>
                <button
                  type="button"
                  onClick={() => {
                    const loggin = prompt('logging');
                    localStorage.setItem('credentials', JSON.stringify({ accessToken: loggin }))

                    navigate({
                      pathname: appRoutes.proxy(),
                      search: queryString.stringify(query),
                    });
                    socket.connect();
                  }}
                >set token</button>
              </div>
            )
          }
        </div>
        <div className={styles.loginFormFooter}>
          <Text level={2} color="secondary">
            {t('Забыли пароль?')}{' '}
            <Link
              to={{
                pathname: appRoutes.recoverPassword(),
                search: queryString.stringify(query),
              }}
            >
              {t('Восстановите здесь')}
            </Link>
          </Text>
        </div>
      </form>
    </div>
  );
};

export default Login;
