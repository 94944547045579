import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import appRoutes from './appRoutes';
import Entry from '../views/Entry';
import Login from '../contents/entry/Login';
import Register from '../contents/entry/Register';
import PhoneConfirmation from '../contents/entry/PhoneConfirmation';
import FillProfile from '../contents/entry/FillProfile';
import App from '../views/App';
import Welcome from '../contents/app/Welcome';
import Chats from '../contents/app/Chats';
import Tutors from '../contents/app/Tutors';
import TutorDetails from '../contents/app/TutorDetails';
import Room from '../views/Room';
import Profile from '../contents/app/Profile';
import ProtectRoute from '../containers/ProtectRoute';
import Schedule from '../contents/app/Schedule';
import Balance from '../contents/app/Balance';
import SetPassword from '../contents/entry/SetPassword';
import Favorite from '../contents/app/Favorite';
import RecoverPasswordRequest from '../contents/entry/RecoverPasswordRequest';
import RecoverPasswordForm from '../contents/entry/RecoverPasswordForm';
import ErrorBoundary from '../containers/shared/ErrorBoundary';
import ProxyRoute from '../containers/ProxyRoute';
import TransferPackage from '../contents/app/TransferPackage';
import Help from '../contents/app/Help';

export default createBrowserRouter([
  {
    path: appRoutes.root(),
    element: <Navigate to={appRoutes.profile()} replace />,
    errorElement: <Navigate to={appRoutes.profile()} replace />
  },
  {
    path: appRoutes.proxy(),
    element: <ProxyRoute />,
    errorElement: <Navigate to={appRoutes.login()} replace />,
  },
  {
    element: <Entry />,
    children: [
      {
        path: appRoutes.login(),
        element: <Login />,
        errorElement: <div>ERROR login</div>
      },
      {
        path: appRoutes.register(),
        element: <Register />,
        errorElement: <div>ERROR Register</div>
      },
      {
        path: appRoutes.registerPhoneConfirmation(),
        element: <PhoneConfirmation />,
        errorElement: <div>ERROR Phone confirmation</div>
      },
      {
        path: appRoutes.setPassword(),
        element: <SetPassword />,
        errorElement: <div>ERROR Set password</div>
      },
      {
        path: appRoutes.registerFillProfile(),
        element: <FillProfile />,
        errorElement: <div>ERROR Fill Profile</div>
      },
      {
        path: appRoutes.recoverPassword(),
        element: <RecoverPasswordRequest />,
        errorElement: <div>ERROR Recover password request</div>
      },
      {
        path: appRoutes.setRecoverPassword(),
        element: <RecoverPasswordForm />,
        errorElement: <div>ERROR recover password form</div>
      },
    ]
  },
  {
    element: <ProtectRoute><App /></ProtectRoute>,
    path: appRoutes.app(),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: appRoutes.app(),
        element: <Welcome />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.help(),
        element: <Help />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.chats(),
        element: <Chats />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.schedule(),
        element: <Schedule />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.balance(),
        element: <Balance />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.tutors(),
        element: <Tutors />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.tutors(':id'),
        element: <TutorDetails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.profile(),
        element: <Profile />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.favorites(),
        element: <Favorite />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.transferPackage(),
        element: <TransferPackage />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    path: appRoutes.room(),
    element: <ProtectRoute><Room /></ProtectRoute>,
    errorElement: <div>ERROR Room</div>
  },
], { basename: '/online-app' });


// Exmpale
// export default createBrowserRouter([
//   {
//     path: routes.root(),
//     element: <Entry />,
//     errorElement: <ErrorPage />
//   },
//   {
//     path: routes.app(),
//     element: <Application />,
//     children: [
//       {
//         path: routes.welcome(),
//         element: <Welcome />,
//       },
//     ],
//   }
// ]);
