import React, { useMemo } from 'react';

import styles from './NearestLesson.module.scss';
import { useGetScheduledLessonsQuery } from '../../../store/apis/lessons/scheduled';
import { addDays, format, formatISO, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import { head } from 'ramda';
import UserInfoSmall from '../../../components/UserInfoSmall';
import { intlFormatDate } from '../../../utils';
import i18n, { dateLocales } from '../../../localizations/i18n';
import { FiArrowRight } from 'react-icons/fi';

const NearestLesson = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const now = useMemo(() => new Date(), []);

  const {
    data: lessons,
    isLoading: isLessonsLoading,
    isFetching: isLessonsFetching,
  } = useGetScheduledLessonsQuery({
    fromTime: formatISO(now),
    toTime: formatISO(addDays(now, 4)),
  });

  const goToCatalog = () => {
    navigate(appRoutes.schedule());
  };

  const lesson = lessons ? head(lessons) : null;

  return (
    <div className={styles.wrapper}>
      {
        lesson
          ? (
            <div className={styles.lesson}>
              <h3 className={styles.title}>{t('Ближайший урок')}</h3>
              <p className={styles.date}>
                {format(parseISO(lesson.lessonTime), 'EEEE', { locale: dateLocales[i18n.language] })}
                {', '}
                {intlFormatDate(lesson.lessonTime)}
              </p>
              <UserInfoSmall
                name={lesson.schedule.tutor.fullName[i18n.language]}
                photoUrl={lesson.schedule.tutor.photoUrl}
                message={lesson.schedule.subject.name[i18n.language]}
              />
              <div className={styles.action}>
                <button
                  onClick={goToCatalog}
                  type="button"
                  className={styles.actionButton}
                >
                  {t('В расписание')}
                  <span>
                    <FiArrowRight />
                  </span>
                </button>
              </div>
            </div>
          ) : (
            <div className={styles.empty}>
              <img src="/online-app/images/no_schedule.png" alt=""/>
              <h3 className={styles.emptyTitle}>{t('В ближайшие семь дней уроков нет')}</h3>
              <Button size="small" onClick={goToCatalog} className="Button_button__JBBzO_Find">
                {t('Найти преподавателя')}
              </Button>
            </div>
          )
      }
      {/*<h3 className={styles.sectionTitle}>{t('Ближайший урок')}</h3>*/}
      {/*<pre>*/}
      {/*  {JSON.stringify(lessons, 0, 2)}*/}
      {/*</pre>*/}
    </div>
  );
};

export default NearestLesson;
