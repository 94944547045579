import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './UnpaidInvoices.module.scss';
import {
  useCancelInvoiceMutation,
  useGetUnpaidInvoicesQuery,
  useLazyGetUnpaidInvoiceDetailsQuery
} from '../../../store/apis/tariffs';
import ContentEmpty from '../../../components/ContentEmpty';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import UserInfoSmall from '../../../components/UserInfoSmall';
import { FiFileText, FiMoreVertical } from 'react-icons/fi';
import Text from '../../../components/Text';
import { formatNumber } from '../../../utils';
import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import PaymentMethodCard from '../../tutor/PaymentMethodCard';
import { keys } from 'ramda';
import PopupMenu, { PopupMenuItem } from '../../../components/PopupMenu';
import { toast } from 'react-toastify';
import { useGetServiceConfigsQuery } from '../../../store/apis/serviceConfigs';
import CountDownTimer from '../../../components/CountDownTimer';
import { addMinutes, parseISO } from 'date-fns';

const UnpaidInvoices = () => {
  const [t, i18n] = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [cancelModal, setCancelModal] = useState({ open: false, invoice: null });

  const {
    data: invoiceList,
    isLoading: isInvoiceListLoading,
    isFetching: isInvoiceListFetching,
  } = useGetUnpaidInvoicesQuery({ page: -1 });

  const {
    data: serviceConfig,
    isFetching: isServiceConfigFetching,
  } = useGetServiceConfigsQuery();

  const [
    getInvoiceDetails,
    {
      data: invoiceDetails,
      isLoading: isInvoiceDetailsLoading,
      isFetching: isInvoiceDetailsFetching,
    }
  ] = useLazyGetUnpaidInvoiceDetailsQuery();

  const [
    cancelInvoice,
    {
      isLoading: isCancelingInvoice,
    }
  ] = useCancelInvoiceMutation();

  const handleClickPay = useCallback((invoiceID) => async () => {
    try {
      await getInvoiceDetails(invoiceID).unwrap();
      setIsOpenModal(true);
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
    // setIsOpenModal(true);
  }, [getInvoiceDetails]);

  const openCancelModal = (invoice) => () => {
    setCancelModal({ open: true, invoice })
  };

  const handleCancelInvoice = async () => {
    try {
      if (!cancelModal.invoice) return;

      await cancelInvoice(cancelModal.invoice.id).unwrap();
      toast.success(t('Счет отменен'));
    } catch (e) {

    }
  };

  if (isInvoiceListLoading || isInvoiceListFetching) return (
    <ContentLoader
      backgroundColor={'#c8c8c8'}
      foregroundColor={'#eceaea'}
      width="100%"
      height="340"
      style={{ padding: '0 1em', boxSizing: 'border-box' }}
      speed={2}
    >
      <rect x="0" y="0" rx="6" ry="6" width="49%" height="340" />
      <rect x="51%" y="0" rx="6" ry="6" width="49%" height="340" />
    </ContentLoader>
  )

  if (invoiceList && invoiceList.results.length === 0) return (
    <ContentEmpty
      imgSrc="/online-app/images/empty-sleeping.png"
      title={t('У Вас нет неоплаченных счетов')}
      subtitle={t('Если по какой-то причине Вы не смогли оплатить счет сразу, то он будет ожидать оплаты здесь')}
    />
  )
  return (
    <div className={styles.container}>
      {
        invoiceList.results.map((invoice) => (
          <div className={styles.item} key={invoice.id}>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <span className={styles.cardHeaderText}>
                  <FiFileText /> {t('Неоплаченный счет')}
                </span>
              </div>
              <PopupMenu
                trigger={
                  <button type="button" className={styles.cardMore}>
                    <FiMoreVertical />
                  </button>
                }
              >
                <PopupMenuItem
                  label={t('Отменить счет')}
                  onClick={openCancelModal(invoice)}
                  type="danger"
                />
              </PopupMenu>
              <UserInfoSmall
                photoUrl={invoice.tutor.photoUrl}
                name={invoice.tutor.fullName?.[i18n.language]}
                message={invoice.tutor.shortInfo[i18n.language]}
              />
              <div className={styles.tariff}>
                <Text level={3} color="secondary">
                  {
                    (invoice.extraPrice && invoice.extraPrice > 0)
                      ? t('Вам необходимо пополнить баланс для пробного урока', { n: invoice.extraPrice })
                      : t('Тарифный план {{tariff}}', { tariff: invoice.tariff.name[i18n.language] })
                  }
                </Text>
                {
                  (invoice.extraPrice && invoice.extraPrice > 0)
                    ? null
                    : (
                      <div className={styles.paymentData}>
                        <div className={styles.paymentDataItem}>
                          <Text level={2}>{t('{{lessonCount}} занятий', { count: invoice.tariff.lessonCount })}</Text>
                        </div>
                        <div className={styles.paymentDataItem}>
                          <Text level={2} bold>{formatNumber(invoice.price)}</Text>
                        </div>
                      </div>
                    )
                }
                <Divider mt={0} mb={12} />
                <div className={styles.paymentData}>
                  <div className={styles.paymentDataItem}>
                    <Text level={2} bold>{t('Сумма к оплате')}</Text>
                  </div>
                  <div className={styles.paymentDataItem}>
                    <Text level={2} bold>
                      {
                        (invoice.extraPrice && invoice.extraPrice > 0)
                          ? formatNumber(invoice.extraPrice)
                          : formatNumber(invoice.price)
                      }
                    </Text>
                  </div>
                </div>
              </div>

              <div className={styles.cardFooter}>
                <div className={styles.cardFooterTimer}>
                  {
                    serviceConfig?.autoDeleteNotPaidScheduleAfterMinutes
                      ? (
                        <CountDownTimer
                          endDate={addMinutes(parseISO(invoice.createdTime), serviceConfig.autoDeleteNotPaidScheduleAfterMinutes)}
                          label={t('Неоплаченный счет аннулируется через')}
                        />
                      ) : null
                  }
                </div>
                <Button
                  size="small"
                  color="secondary"
                  fullWidth
                  onClick={handleClickPay(invoice.id)}
                  loading={isInvoiceDetailsLoading || isInvoiceDetailsFetching}
                >
                  {t('Оплатить')}
                </Button>
                {
                  invoiceDetails && (
                    <Modal
                      open={isOpenModal}
                      onClose={() => {
                        setIsOpenModal(false);
                      }}
                      variant="secondary"
                      title={t('Способ оплаты')}
                    >
                      {(close) => (
                        <PaymentMethodCard
                          tariff={invoice.tariff}
                          paymentOrder={invoiceDetails.orderId}
                          amount={invoiceDetails.amount}
                          methods={invoiceDetails.paymentWays}
                          onSuccess={() => {
                            close();
                          }}
                        />
                      )}
                    </Modal>
                  )
                }
                <Modal
                  open={cancelModal.open}
                  onClose={() => {
                    setCancelModal({ open: false, invoice: null });
                  }}
                  variant="secondary"
                  title={t('Вы уверены что хотите отменить счет?')}
                >
                  {(close) => (
                    <div className={styles.cancelModal}>
                      <p className={styles.cancelModalText}>
                        {t('Вы не сможете оплатить этот счет, а Ваше выбранное расписание по этому счету будет отменено')}
                      </p>
                      <div className={styles.cancelModalFooter}>
                        <div className={styles.cancelModalFooterAction}>
                          <Button
                            size="small"
                            color="primary"
                            onClick={close}
                            fullWidth
                          >
                            {t('Нет, закрыть окно')}
                          </Button>
                        </div>
                        <div className={styles.cancelModalFooterAction}>
                          <Button
                            size="small"
                            color="danger"
                            onClick={handleCancelInvoice}
                            loading={isCancelingInvoice}
                            fullWidth
                          >
                            {t('Да, я отменяю счет')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Modal>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default UnpaidInvoices;
