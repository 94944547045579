import React, { useCallback, useMemo } from 'react';

import styles from './ScheduleTodayList.module.scss';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import ScheduleCard from '../ScheduleCard';
import { useTranslation } from 'react-i18next';
import {
  useGetCancelledListQuery,
  useGetRescheduledLessonListQuery,
  useGetScheduledLessonsQuery
} from '../../../store/apis/lessons/scheduled';
import { formatDate, formatTime } from '../../../utils';
import {
  addDays,
  compareAsc,
  differenceInCalendarDays,
  endOfDay,
  format,
  formatISO,
  parseISO,
  subHours
} from 'date-fns';
import ContentLoader from 'react-content-loader';
import ContentEmpty from '../../../components/ContentEmpty';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import { keys, sort } from 'ramda';
import { dateLocales } from '../../../localizations/i18n';
import appSettings from '../../../appSettings';

const ScheduleTodayList = () => {
  const today = useMemo(() => new Date(), []);
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();

  const goToTutorsSearch = useCallback(() => {
    navigate(appRoutes.tutors());
  }, [navigate]);

  const {
    data: lessons,
    isLoading: isLessonsLoading,
    isFetching: isLessonsFetching,
  } = useGetScheduledLessonsQuery({
    fromTime: formatISO(subHours(today, 3)),
    toTime: formatISO(endOfDay(addDays(today, appSettings.amountOfDaysForNearSchedule))),
  });

  const {
    data: rescheduledLessons,
    isLoading: isRescheduledLessonsLoading,
    isFetching: isRescheduledLessonsFetching,
  } = useGetRescheduledLessonListQuery();

  const {
    data: cancelledLessons,
    isLoading: isCancelledLessonsLoading,
    isFetching: isCancelledLessonsFetching,
  } = useGetCancelledListQuery({
    lessonTimeFrom: formatISO(today),
    lessonTimeTo: formatISO(endOfDay(addDays(today, appSettings.amountOfDaysForNearSchedule))),
    typeAliasNot: 'transfer',
    page: -1,
  });

  const dayNames = useMemo(() => [
    t('Сегодня'),
    t('Завтра'),
    t('Послезавтра')
  ], [t]);

  const renderContent = useCallback((loading, lessons, rescheduleRequestLessons, cancelledLessons) => {
    if (loading) return (
      [1, 2, 3].map((id) => (
        <div className={styles.listItem} key={id}>
          <ContentLoader
            backgroundColor={'#c8c8c8'}
            foregroundColor={'#eceaea'}
            width="100%"
            height="300"
            speed={2}
          >
            <rect x="0" y="0" rx="20" ry="20" width="100%" height="100%" />
          </ContentLoader>
        </div>
      ))
    );

    if (!lessons || lessons.length === 0) return (
      <ContentEmpty
        imgSrc="/online-app/images/empty-sleeping.png"
        title={t('У Вас нет запланированных занятий')}
        subtitle={t('Здесь будут отображаться ваши запланированные занятия и возможность подключиться к уроку')}
        actionText={t('Найти репетитора')}
        onAction={goToTutorsSearch}
        btnClasses="Button_button__JBBzO_Find"
      />
    );

    const lessonsGroupedByDay = lessons.reduce((acc, lesson) => {
      const differenceDays = differenceInCalendarDays(parseISO(lesson.lessonTime), today);
      const currentLessons = acc[differenceDays];
      if (currentLessons) {
        return {
          ...acc,
          [differenceDays]: [...currentLessons, lesson],
        };
      }
      return {
        ...acc,
        [differenceDays]: [lesson],
      };
    }, {});

    const mergedWithRescheduledLessons = rescheduleRequestLessons
      ? rescheduleRequestLessons.reduce((acc, lesson) => {
        // statusId === 1 ---- new
        // statusId === 2 ---- approved
        // statusId === 3 ---- rejected
        const statusId = lesson.status.id;
        if (statusId === 2) return acc;

        const differenceDays = differenceInCalendarDays(parseISO(lesson.fromLessonTime), today);
        if (differenceDays > appSettings.amountOfDaysForNearSchedule) return acc;
        if (differenceDays < 0) return acc;

        const preparedLessonStructure = {
          id: lesson.id,
          lessonTime: lesson.fromLessonTime,
          schedule: lesson.lessonSchedule,
          rescheduleData: statusId === 1 ? {
            ...lesson,
            fromLessonTime: format(parseISO(lesson.fromLessonTime), 'dd MMMM, HH:mm', { locale: dateLocales[i18n.language] }),
            toLessonTime: format(parseISO(lesson.toLessonTime), 'dd MMMM, HH:mm', { locale: dateLocales[i18n.language] }),
          } : null,
          cancelData: statusId === 3 ? {
            ...lesson,
            comment: (lesson.autorType.id === 2)
              ? t('Урок был отменен автоматически, поскольку Вы отказали в переносе или не отреагировали вовремя')
              : t('Урок был отменен автоматически, поскольку Учитель отказал в переносе или не отреагировал вовремя'),
            lessonTime: format(parseISO(lesson.fromLessonTime), 'dd MMMM, HH:mm', { locale: dateLocales[i18n.language] }),
          } : null,
        };

        const currentLessons = acc[differenceDays];
        if (currentLessons) {
          return {
            ...acc,
            [differenceDays]: [...currentLessons, preparedLessonStructure],
          };
        }
        return {
          ...acc,
          [differenceDays]: [preparedLessonStructure],
        };
      }, lessonsGroupedByDay)
      : lessonsGroupedByDay;

    const mergedWithCancelled = cancelledLessons
      ? cancelledLessons.reduce((acc, lesson) => {
        const differenceDays = differenceInCalendarDays(parseISO(lesson.lessonTime), today);

        const preparedLessonStructure = {
          id: lesson.id,
          lessonTime: lesson.lessonTime,
          schedule: lesson.lessonSchedule,
          cancelData: {
            ...lesson,
            lessonTime: format(parseISO(lesson.lessonTime), 'dd MMMM, HH:mm', { locale: dateLocales[i18n.language] }),
          },
        };

        const currentLessons = acc[differenceDays];
        if (currentLessons) {
          return {
            ...acc,
            [differenceDays]: [...currentLessons, preparedLessonStructure],
          };
        }
        return {
          ...acc,
          [differenceDays]: [preparedLessonStructure],
        };
      }, mergedWithRescheduledLessons)
      : mergedWithRescheduledLessons

    return keys(mergedWithCancelled).map((dayKey) => (
      <React.Fragment key={dayKey}>
        <div className={styles.listHeader}>
          <Text level={2} bold color="secondary" noPadding>
            {dayNames[dayKey]}
          </Text>
        </div>
        {
          sort(
            (prev, next) => compareAsc(parseISO(prev.lessonTime), parseISO(next.lessonTime)),
            mergedWithCancelled[dayKey]
          ).map(({
            id,
            lessonTime,
            schedule,
            cancelAvailable,
            transferAvailable,
            joinAvailable,
            rescheduleData,
            cancelData,
            classroomId,
            ...rest
          }) => (
            <div className={styles.listItem} key={id}>
              <ScheduleCard
                scheduleId={schedule.id}
                roomId={id}
                lessonTime={lessonTime}
                date={formatDate(lessonTime) || '-'}
                time={formatTime(lessonTime) || '-'}
                tutorPhoto={schedule.tutor.photoUrl}
                tutorName={schedule.tutor.fullName?.[i18n.language]}
                tutorId={schedule.tutor.id}
                tutorGraduation={schedule.tutor.shortInfo[i18n.language]}
                cancelAvailable={cancelAvailable}
                transferAvailable={transferAvailable}
                startAvailable={joinAvailable}
                classroomId={classroomId}
                rescheduleData={rescheduleData}
                cancelData={cancelData}
                subject={schedule.subject.name[i18n.language]}
                isActive={schedule.isActive}
              />
            </div>
          ))
        }
      </React.Fragment>
    ));
  }, [today, dayNames, goToTutorsSearch, i18n.language, t]);

  // TODO: Написать обработчики подключения к уроку
  return (
    <div>
      <Title level={4}>{t('Ближайшие уроки')}</Title>
      <div className={styles.list}>
        {
          renderContent(
            isLessonsFetching
              || isLessonsLoading
              || isRescheduledLessonsLoading
              || isRescheduledLessonsFetching
              || isCancelledLessonsLoading
              || isCancelledLessonsFetching,
            lessons,
            rescheduledLessons?.results,
            cancelledLessons?.results,
          )
        }
      </div>
    </div>
  );
};

export default ScheduleTodayList;
