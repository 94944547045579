import React, { useCallback, useMemo } from 'react';

import styles from './BalanceDebit.module.scss';
import Table from '../../../components/Table';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text';
import Pagination from '../../../components/Pagination';
import UserInfoSmall from '../../../components/UserInfoSmall';
import { useGetLessonsQuery } from '../../../store/apis/lessons/scheduled';
import useQueryString from '../../../hooks/useQueryString';
import ContentLoader from 'react-content-loader';
import { formatNumber, intlFormatDate } from '../../../utils';
import queryString from 'query-string';
import { Link, useNavigate } from 'react-router-dom';
import ContentEmpty from '../../../components/ContentEmpty';
import appRoutes from '../../../app/appRoutes';

const BalanceDebit = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();

  const {
    page = 1,
    pageSize = 20,
    state = 2,
    ...restQuery
  } = useQueryString();

  const {
    data: lessons,
    isLoading,
    isFetching,
  } = useGetLessonsQuery({
    page, pageSize, state, ...restQuery,
  });

  const columns = useMemo(() => [
    {
      id: 'tutor',
      accessor: 'tutor',
      Header: t('Учитель'),
      Cell: ({ value: data }) => (
        <Link to={appRoutes.tutors(data.id)} className={styles.tableLink}>
          <UserInfoSmall
            photoUrl={data.photoUrl}
            name={data.fullName?.[i18n.language]}
            message={data.shortInfo[i18n.language]}
          />
        </Link>
      ),
    },
    {
      id: 'subject',
      accessor: 'subject',
      Header: t('Предмет'),
    },
    {
      id: 'lessonDate',
      accessor: 'lessonDate',
      Header: t('Дата урока'),
      Cell: ({ value }) => (
        <Text level={3} color="secondary" noPadding>{value}</Text>
      )
    },
    {
      id: 'amount',
      accessor: 'amount',
      Header: t('Сумма'),
      Cell: ({ value }) => (
        <Text level={2} bold noPadding>{value}</Text>
      )
    },
  ], [i18n.language, t]);

  const changePage = useCallback((page) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page, ...restQuery }),
    });
  }, [navigate, restQuery]);

  const goToTutorsSearch = useCallback(() => {
    navigate(appRoutes.tutors());
  }, [navigate]);

  if (isLoading) return (
    <ContentLoader
      backgroundColor={'#c8c8c8'}
      foregroundColor={'#eceaea'}
      width="100%"
      height="340"
      style={{ padding: '0', boxSizing: 'border-box' }}
      speed={2}
    >
      {[0, 1, 2, 3, 4, 5].map((id) => {
        const height = 48;
        const positionY = id * (height + 10);
        return (
          <rect x="0" y={positionY} rx="6" ry="6" width="100%" height={height} key={id} />
        )
      })}
    </ContentLoader>
  );

  if (lessons && lessons.results.length === 0) return (
    <ContentEmpty
      imgSrc="/online-app/images/empty-sleeping.png"
      title={t('У Вас пока не было ни одного пройденного занятия')}
      subtitle={t('Здесь будет отображаться история списаний средств с приобретенных пакетов')}
      actionText={t('Хотите найти репетитора?')}
      onAction={goToTutorsSearch}
      btnClasses="Button_button__JBBzO_Find"
    />
  )

  const data = lessons?.results.map((lesson) => ({
    id: lesson.id,
    lessonDate: intlFormatDate(lesson.lessonTime),
    subject: lesson.subject.name[i18n.language],
    tutor: lesson.tutor,
    amount: `-${t('{{amount}} сум', { amount: formatNumber(lesson.price) })}`,
  }));

  return (
    <>
      <div className={styles.debit}>
        <Table
          columns={columns}
          data={data}
          loading={isFetching}
        />
      </div>
      <div className={styles.debitFooter}>
        <Pagination
          count={lessons?.totalPages}
          currentPage={page}
          onPageChange={changePage}
        />
      </div>
    </>
  );
};

export default BalanceDebit;
